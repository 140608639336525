<template>
  <div class="userpage">
    <v-toolbar flat dense>
      <v-toolbar-title>My Profile</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" x-small text @click.stop="profileDialog = true">
        update profile
      </v-btn>
      <MyProfile
        :profileDialog="profileDialog"
        @closeProfile="closeProfile"
        @submitProfile="submitProfile"
      />
    </v-toolbar>
    <v-divider></v-divider>
    <v-card flat>
      <v-card-text>
        <v-tabs v-model="proTab" centered>
          <v-tab key="mybrief">brief</v-tab>
          <v-tab key="mydetails">details</v-tab>
        </v-tabs>
        <v-tabs-items v-model="proTab">
          <v-tab-item key="mybrief" style="padding-top: 20px">
            {{ profilia.brief }}
          </v-tab-item>
          <v-tab-item key="mydetails" style="padding-top: 20px">
            <div class="scrollable" :style="{ height: panheight }">
              <div v-html="profilia.details"></div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";
import MyProfile from "../../../components/accounts/profile.vue";

export default {
  components: { MyProfile },
  mixins: [Utils],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    profilia: {},
    proTab: null,
    profileDialog: false,
  }),
  computed: {
    panheight: function () {
      let h = this.windowSize.y - 450 + "px";
      return h;
    },
  },
  created() {
    this.detail();
  },
  methods: {
    detail() {
      this.loading = true;
      let self = this;
      Restful.subscribers
        .detail()
        .then((response) => {
          console.log(response.data);
          this.details = response.data;
          this.profilia = response.data.profile;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeProfile(p) {
      console.log(p);
      this.profileDialog = p.state;
    },
    submitProfile(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers
        .profileUpdate(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.detail();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.profileDialog = p.state;
    },
  },
};
</script>
