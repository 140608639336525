<template>
  <v-dialog v-model="profileDialog" max-width="1000" persistent scrollable>
    <v-card :loading="loading" loader-height="2">
      <v-toolbar flat dense>
        <v-toolbar-title>Update Profile</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-tabs v-model="tabs" centered>
          <v-tab key="brief">brief</v-tab>
          <v-tab key="details">details</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs">
          <v-tab-item key="brief" style="padding-top: 20px">
            <v-textarea
              v-model="create.brief"
              auto-grow
              label="My brief"
              outlined
              maxLength="600"
              counter
            ></v-textarea>
          </v-tab-item>
          <v-tab-item key="background" style="padding-top: 20px">
            <vue-editor
              v-model="create.details"
              placeholder="You can write a detailed profile of yourself as a practitioner here."
            ></vue-editor>

            <!-- <v-textarea
              v-model="create.details"
              auto-grow
              label="My details"
              outlined
            ></v-textarea> -->
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              :disabled="$v.create.$invalid"
              color="blue"
              small
              text
              @click="submit"
              v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
lore
<script>
import Restful from "@/services/RestFul";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { VueEditor } from "vue2-editor";

export default {
  mixins: [validationMixin],
  props: {
    profileDialog: Boolean,
  },
  components: {
    VueEditor,
  },
  // mixins: [Utils],
  data() {
    return {
      tabs: null,
      create: {
        brief: null,
        details: null,
      },
    };
  },
  validations: {
    create: {
      brief: {
        required,
      },
      details: {
        required,
      },
    },
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers
        .detail()
        .then((response) => {
          console.log(response.data);
          this.create.brief = response.data.profile.brief;
          this.create.details = response.data.profile.details;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.loading = false;
        });
    },
    closeDialog() {
      let payload = { state: false };
      this.$emit("closeProfile", payload);
    },
    submit() {
      let brief = this.create.brief;
      let details = this.create.details;
      let profile = {
        brief,
        details,
      };
      let data = { profile };

      let payload = { state: false, data: data };
      // console.log("payload", payload);
      console.log("payload", JSON.stringify(payload));
      this.$emit("submitProfile", payload);
    },
  },
};
</script>
